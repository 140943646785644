const pino = require('pino');
let logDirectory = '';
let isCSR = typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.platform == 'development' ? true : (typeof window !== 'undefined' && window);
//console.log("isCSRisCSR", isCSR);

if (isCSR) {
    logDirectory = '';
}

function createDailyFileTransport(logDirectory) {
    const logFilename = `m360_rendering-${new Date().toISOString().slice(0, 10)}.log`;
    return [
        {
            target: 'pino/file',
            options: {
                destination: logDirectory + logFilename,
                mkdir: true,
                colorize: false
            },
        },
        {
            target: 'pino-pretty'
        }
    ];
}

const logger = pino({
    serializers: {
        err: pino.stdSerializers.err
    },
    timestamp: pino.stdTimeFunctions.isoTime,
    transport: { targets: createDailyFileTransport(logDirectory) },
    level: 'info'
});

module.exports = logger;