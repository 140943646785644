// AmpArticleShow.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PAGE_TYPES } from '@fronend/common/constants';
import { getHostName, getPageData, getRenderOut } from '@fronend/common/utils';
import { fetchHomeDataIfNeeded } from '@components/Home/HomeActions';
import { connect } from 'react-redux';
import './../../../reducers/articleshow/amparticleshow';

AmpArticleShow.events = {
	SUCCESS: 'AMP_ARTICLE_SHOW_SUCCESS',
	FAILURE: 'AMP_ARTICLE_SHOW_FAILURE'
};

AmpArticleShow.fetchData = async function({ dispatch, query, params, cookies, headers, hostname, req, res, loading }) {
	return dispatch(fetchHomeDataIfNeeded(params, query, cookies, headers, hostname, req, PAGE_TYPES.AmpArticleShow, AmpArticleShow.events, res, loading));
};

function AmpArticleShow(props: { data: any, iscsr: any; }) {

	const [pageJson, setpageJson] = useState<any>(null);
	useEffect( () => {
		if( !props.data && !pageJson ) {
			const hostname = getHostName();
			const Promise1 = getPageData(hostname, PAGE_TYPES.AmpArticleShow);
			Promise1.then( res => {
				setpageJson(res);
			} );
		}
	}, [pageJson] );
	
	return (
		<div>
			{getRenderOut(props, pageJson, null, true)}
		</div>	
	);

}


const mapStateToProps = (state) => ({
	data: state.amparticleshow.component_data, 
	iscsr: state.iscsr,
});

export default connect(mapStateToProps)(AmpArticleShow);
