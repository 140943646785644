import logger from '../../logging/log';
import reducerRegistry from '../reducerRegistry';
import VideoList from '@components/VideoList/VideoList';

function videolist(
	state = {
		isFetching: false,
		error: false,
		lastUpdated: 0,
		value: {},
		watch: {
			value: {},
			isFetching: false,
		},
	},
	action
) {
	switch (action.type) {

		case VideoList.events.SUCCESS: {
			const type = 'component_data';
			if (!(type in state)) {
				state[type] = {
					value: {},
					isFetching: false,
				};
			}

			state[type].value = action.payload;
			state[type].isFetching = false;
			return {
				...state,
				isFetching: false,
			};
		}

		case VideoList.events.FAILURE: {
			return {
				...state,
				isFetching: false,
				error: true,
			};
		}

		default:
			return state;
	}
}

reducerRegistry.register('videolist', videolist);
export default videolist;
