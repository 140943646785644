import reducerRegistry from '../reducerRegistry';
import AmpVideoShow from '@components/VideoShow/AmpVideoShow';

function ampvideoshow(
	state = {
		isFetching: false,
		error: false,
		lastUpdated: 0,
		value: {},
		watch: {
			value: {},
			isFetching: false,
		},
	},
	action
) {
	switch (action.type) {

	case AmpVideoShow.events.SUCCESS: {
		const type = 'component_data';
		if (!(type in state)) {
			state[type] = {
				value: {},
				isFetching: false,
			};
		}

		state[type].value = action.payload;
		state[type].isFetching = false;
		return {
			...state,
			isFetching: false,
		};
	}
	
	case AmpVideoShow.events.FAILURE: {
		return {
			...state,
			isFetching: false,
			error: true,
		};
	}

	default:
		return state;
	}
}

//console.log('amparticleshow reducer call');
reducerRegistry.register('ampvideoshow', ampvideoshow);
export default ampvideoshow;
