import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';
//import rootReducer from 'reducers';
import { routerReducer } from 'react-router-redux';
import reducerRegistry from './../reducers/reducerRegistry';

const middleware = [thunk];

// if (typeof window != 'undefined') {
//   middleware.push(logger);
//}

middleware.push();

const combine = (reducers, initialState) => {
	const reducerNames = Object.keys(reducers);
	//initialState = {routing: routerReducer, ...initialState};
	if (typeof initialState !== 'undefined') {
		Object.keys(initialState).forEach((item) => {
			if (reducerNames.indexOf(item) === -1) {
				reducers[item] = (state = null) => state;
			}
		});
	}
	if (reducerNames.indexOf('routing') == -1) {
		reducers['routing'] = routerReducer;
	}

	return combineReducers(reducers);
};

export default function configureStore(initialState) {
	const rootReducer = combine(reducerRegistry.getReducers(), initialState);

	const store = createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware))
	);

	/* eslint-disable */
	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("../reducers", () => {
			const nextRootReducer = require("../reducers").default;
			store.replaceReducer(nextRootReducer);
		});
	}
	/* eslint-enable */

	reducerRegistry.setChangeListener((reducers) => {
		store.replaceReducer(combine(reducers, initialState));
	});

	return store;
}
