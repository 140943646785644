import Home from '@components/Home/Home';
import reducerRegistry from '../reducerRegistry';
import logger from '../../logging/log';

function home(
	state = {
		isFetching: false,
		error: false,
		lastUpdated: 0,
		value: {},
		watch: {
			value: {},
			isFetching: false,
		},
	},
	action
) {
	switch (action.type) {

		case Home.events.SUCCESS: {
			const type = 'component_data';
			// if (!state.hasOwnProperty(type)) {
			if (!(type in state)) {
				state[type] = {
					value: {},
					isFetching: false,
				};
			}

			state[type].value = action.payload;
			state[type].isFetching = false;
			return {
				...state,
				isFetching: false,
			};
		}

		case Home.events.FAILURE: {
			return {
				...state,
				isFetching: false,
				error: true,
			};
		}

		default:
			return state;
	}
}

reducerRegistry.register('home', home);
export default home;
