import React from 'react';
import { PAGE_TYPES } from '@fronend/common/constants';
import { getMSID, fetchMedleyData } from '@fronend/common/utils';
import logger from '../../../logging/log';

export const getSeoData = (data: any, seoDataMaps: any) => {
    try {

        // console.log("inside getSeoData data : " , data);
        // console.log("inside getSeoData topicCookieflag : " + data.cookiesTopicRobots);
        interface SeoData {
            type: string;
            viewport: string;
            title: string;
            description: string;
            keywords: string;
            ogUrl: string;
            robots: string;
            ogTitle: string;
            pageUrl: string;
            ogImage: string;
            ogDescription: string;
            ogType: string;
            ogSiteName: string;
            ogImageWidth: string;
            ogImageHeight: string;
            imageWidth: string;
            imageHeight: string;
            twitterUrl: string;
            twitterImage: string;
            twitterTitle: string;
            twitterDescription: string;
            twitterCard: string;
            twitterSite: string;
            fbAdmins: string;
            fbPages: string;
            fbAppId: string;
            googleSiteVerification: string;
            newsKeywords: string;
            mobileWebAppCapable: string;
            themeColor: string;
            appleMobileWebAppCapable: string;
            handheldFriendly: string;
            mobileOptimized: string;
            xUACompatible: string;
            contentType: string;
            contentLanguage: string;
            yKey: string;
            canonical: string;
            alternate: string;
            amphtml: string;
            websiteName: string;
            websiteUrl: string;
            logoUrl: string;
            logoHeight: string;
            logoWidth: string;
            navigationItems: Array<Object>;
            publishedDate: string;
            modifiedDate: string;
            uploadDate: string;
            author: Array<Object>;
            thumbnailUrl: string;
            articleBody: string;
            duration: string;
            googleSchema: Object;
            slideShowdata: Array<Object>;
            imgDomain: string;
            breadcrumbs: Array<Object>;
            headerNavigationItems: Array<Object>;
            footerNavigationItems: Array<Object>;
            videoEmbeds: Array<Object>;
            videocmsEmbeds: Array<Object>;
        };


        const seoData: SeoData = {
            type: '',
            viewport: '',
            title: '',
            description: '',
            keywords: '',
            ogUrl: '',
            robots: '',
            ogTitle: '',
            pageUrl: '',
            ogImage: '',
            ogDescription: '',
            ogType: '',
            ogSiteName: '',
            ogImageWidth: '',
            ogImageHeight: '',
            imageWidth: '',
            imageHeight: '',
            twitterUrl: '',
            twitterImage: '',
            twitterTitle: '',
            twitterDescription: '',
            twitterCard: '',
            twitterSite: '',
            fbAdmins: '',
            fbPages: '',
            fbAppId: '',
            googleSiteVerification: '',
            newsKeywords: '',
            mobileWebAppCapable: '',
            themeColor: '',
            appleMobileWebAppCapable: '',
            handheldFriendly: '',
            mobileOptimized: '',
            xUACompatible: '',
            contentType: '',
            contentLanguage: '',
            yKey: '',
            canonical: '',
            alternate: '',
            amphtml: '',
            websiteName: '',
            websiteUrl: '',
            logoUrl: '',
            logoHeight: '',
            logoWidth: '',
            navigationItems: [], // not set
            publishedDate: '',
            modifiedDate: '',
            uploadDate: '',
            author: [],
            thumbnailUrl: '',
            articleBody: '',
            duration: '',
            googleSchema: {},
            slideShowdata: [],
            imgDomain: '',
            breadcrumbs: [],
            headerNavigationItems: [],
            footerNavigationItems: [],
            videoEmbeds: [],
            videocmsEmbeds: []
        };

        let seoDataMap: any;
        if(seoDataMaps !== undefined && seoDataMaps && Object.keys(seoDataMaps).length > 0) {
            seoDataMaps.map((item: any, index: number) => {
                if(item !== undefined && item && Object.keys(item).length > 0 && item.type) {
                    if(item.type === "Breadcrumb") {
                        if(item.breadcrumbs) seoData.breadcrumbs = item.breadcrumbs;
                    }
                    // else if(item.type === "Header") {
                    //     if(item.headerNavigationItems && Object.keys(item.headerNavigationItems).length > 0) seoData.headerNavigationItems = item.headerNavigationItems;
                    // }
                    // else if(item.type === "Footer") {
                    //     if(item.footerNavigationItems && Object.keys(item.footerNavigationItems).length > 0) seoData.footerNavigationItems = item.footerNavigationItems;
                    // }
                    else {
                        seoDataMap = item;
                    }
                }
            });
        }

        const pageType = data.theme.page.type;
        const hostname = data.hostname;
        const pathname = data.pathname.split('?')[0];

        // logger.info("hostname : ", hostname);
        // logger.info("pathname : ", pathname);
        // logger.info("setSeoData_pageType: ", pageType);
        // logger.info("setSeoData_seoDataMap :::: "+ seoDataMap);
        // logger.info("setSeoData_websiteData : ", data);

        let BreadcrumbList: any = {};
        let NewsMediaOrganization: any = {};
        let WebPage: any = {};
        // let SiteNavigationElement: any = {};
        let NewsArticle: any = {};
        let Article: any = {};
        let VideoObject: any = {};
        let MediaGallery: any = {};
        let WebSite: any = {};
        let VideoObjectEmbed: Array<Object> = [];

        if (data && data.websiteResouces && data.websiteResouces.socialPlatformList) {
            const facebook = (data.websiteResouces.socialPlatformList.filter((o: { name: string; }) => o.name == "Facebook"))[0];
            const twitter = (data.websiteResouces.socialPlatformList.filter((o: { name: string; }) => o.name == "Twitter"))[0];
            if (facebook && facebook.pageId) seoData.fbPages = facebook.pageId;
            if (facebook && facebook.admin) seoData.fbAdmins = facebook.admin;
            if (facebook && facebook.appId) seoData.fbAppId = facebook.appId;
            if (twitter && twitter.site) seoData.twitterSite = twitter.site;
        }

        if (data && data.websiteResouces && data.websiteResouces.googleSiteVerification) seoData.googleSiteVerification = data.websiteResouces.googleSiteVerification; 
        if (data && data.websiteResouces && data.websiteResouces.themeColor) seoData.themeColor = data.websiteResouces.themeColor;

        var domain: any = "";
        var imgDomain: any = "https://vsp1images.indiatimes.com";
        if (hostname !== undefined && hostname) {
            domain = "https://" + hostname;
        }
        if (domain) {
            imgDomain = domain;
        }
        if(imgDomain) seoData.imgDomain = imgDomain;
        if (imgDomain && seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
            if (seoDataMap.ogImage && !seoDataMap.ogImage.startsWith('http') && seoDataMap.ogImage.startsWith('/')) seoDataMap.ogImage = imgDomain + seoDataMap.ogImage;
            else if (seoDataMap.ogImage && !seoDataMap.ogImage.startsWith('http')) seoDataMap.ogImage = imgDomain + '/' + seoDataMap.ogImage;
            if (seoDataMap.twitterImage && !seoDataMap.twitterImage.startsWith('http') && seoDataMap.twitterImage.startsWith('/')) seoDataMap.twitterImage = imgDomain + seoDataMap.twitterImage;
            else if (seoDataMap.twitterImage && !seoDataMap.twitterImage.startsWith('http')) seoDataMap.twitterImage = imgDomain + '/' + seoDataMap.twitterImage;
            if (seoDataMap.thumbnailUrl && !seoDataMap.thumbnailUrl.startsWith('http') && seoDataMap.thumbnailUrl.startsWith('/')) seoDataMap.thumbnailUrl = imgDomain + seoDataMap.thumbnailUrl;
            else if (seoDataMap.thumbnailUrl && !seoDataMap.thumbnailUrl.startsWith('http')) seoDataMap.thumbnailUrl = imgDomain + '/' + seoDataMap.thumbnailUrl;
        }

        if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
            if(seoDataMap.publishedDate) seoData.publishedDate = convertTimestampToISO(seoDataMap.publishedDate);
            if(seoDataMap.modifiedDate) seoData.modifiedDate = convertTimestampToISO(seoDataMap.modifiedDate);
            if(seoDataMap.uploadDate) seoData.uploadDate = convertTimestampToISO(seoDataMap.uploadDate);
            if(seoDataMap.author) seoData.author = seoDataMap.author;
            if(seoDataMap.thumbnailUrl) seoData.thumbnailUrl = seoDataMap.thumbnailUrl;
            if(seoDataMap.articleBody) seoData.articleBody = seoDataMap.articleBody;
            if(seoDataMap.duration) seoData.duration = msToISO8601(seoDataMap.duration);
            if(seoDataMap.imageWidth) seoData.imageWidth = seoDataMap.imageWidth;
            if(seoDataMap.imageHeight) seoData.imageHeight = seoDataMap.imageHeight;
        }

        if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0 && seoDataMap.canonical !== undefined && seoDataMap.canonical) {
            // Remove trailing slash if it exists
            if (!seoDataMap.canonical.startsWith('http')) {
                if (!seoDataMap.canonical.startsWith('/')) {
                    seoDataMap.canonical = domain + '/' + seoDataMap.canonical;
                } else {
                    seoDataMap.canonical = domain + seoDataMap.canonical;
                }
            }
        }

        if (data !== undefined && data && data.isDemoSite !== undefined && data.isDemoSite !== null && data.isDemoSite == true) {
            seoData.robots = "noindex,nofollow";
            logger.info("data.isDemoSite == true ==>> seoData.robots = noindex,nofollow");
        }

        if (data !== undefined && data && data.websiteResouces) {
            if (data.websiteName) seoData.websiteName = data.websiteName;
            if (domain) seoData.websiteUrl = domain;
            if (data.websiteResouces.imageWidth) seoData.logoWidth = '96';

            if (data.websiteResouces && data.websiteResouces.imageHeight && data.websiteResouces.imageWidth) {
                seoData.logoHeight = resizeHeight(data.websiteResouces.imageHeight, data.websiteResouces.imageWidth, 96);
            }

            if (data.websiteResouces.mainLogo) seoData.logoUrl = imgDomain + `/thumb/${data.websiteResouces.mainLogo}.cms?imgsize=422424&width=96&resizemode=4`;
        }


        if (pageType === PAGE_TYPES.HomePage) {

            if (data !== undefined && data && data.websiteResouces) {
                if (data.websiteResouces.websiteTitle) seoData.title = data.websiteResouces.websiteTitle;
                if (data.websiteResouces.websiteTitle) seoData.ogTitle = data.websiteResouces.websiteTitle;
                if (data.websiteResouces.websiteTitle) seoData.twitterTitle = data.websiteResouces.websiteTitle;
                if (data.websiteResouces.siteDescription) seoData.description = data.websiteResouces.siteDescription;
                if (data.websiteResouces.siteDescription) seoData.ogDescription = data.websiteResouces.siteDescription;
                if (data.websiteResouces.siteDescription) seoData.twitterDescription = data.websiteResouces.siteDescription;
                if (data.websiteResouces.keywords) seoData.keywords = data.websiteResouces.keywords;
                if (seoData.logoUrl) seoData.ogImage = seoData.logoUrl;
                if (seoData.logoUrl) seoData.twitterImage = seoData.logoUrl;
                if (data.websiteResouces.imageWidth) seoData.ogImageWidth = data.websiteResouces.imageWidth;
                if (data.websiteResouces.imageHeight) seoData.ogImageHeight = data.websiteResouces.imageHeight;
                if (domain) seoData.ogUrl = domain;
                if (domain) seoData.twitterUrl = domain;
                if (data.websiteResouces.siteLanguage) seoData.contentLanguage = data.websiteResouces.siteLanguage;
                seoData.ogType = "website";
                if (domain) seoData.canonical = domain;
            }

            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            WebSite = getWebSiteSchema(seoData);
        }
        else if (pageType === PAGE_TYPES.ArticleShow || pageType === PAGE_TYPES.AmpArticleShow || pageType === PAGE_TYPES.ArticleShowPreview || pageType === PAGE_TYPES.AmpArticleShowPreview) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.ogUrl) seoData.ogUrl = domain + seoDataMap.ogUrl;
                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                if (seoDataMap.ogType) seoData.ogType = seoDataMap.ogType;
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                if (seoDataMap.twitterUrl) seoData.twitterUrl = domain + seoDataMap.twitterUrl;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;
                if (seoDataMap.newsKeywords) seoData.newsKeywords = seoDataMap.newsKeywords;
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (seoDataMap.canonical) seoData.canonical = seoDataMap.canonical;
                if (seoDataMap.amphtml) seoData.amphtml = domain + seoDataMap.amphtml;
                if (seoDataMap.noindexAndNofollow) seoData.robots = "noindex,nofollow";
                // if (seoDataMap.videoEmbeds) seoData.videoEmbeds = seoDataMap.videoEmbeds;
                if (seoDataMap.videocmsEmbeds) seoData.videocmsEmbeds = seoDataMap.videocmsEmbeds;
            }

            if( pageType === PAGE_TYPES.ArticleShowPreview || pageType === PAGE_TYPES.AmpArticleShowPreview ) {
                seoData.robots = "noindex,nofollow";
            }

            if(pageType === PAGE_TYPES.ArticleShow) BreadcrumbList = getBreadcrumbListSchema(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            // NewsArticle = getNewsArticleSchema(seoData);
            Article = getArticleSchema(seoData);
            if(seoData.videocmsEmbeds && seoData.videocmsEmbeds.length > 0) {
                seoData.videocmsEmbeds.forEach((item: any) => {

                    if (imgDomain && item && item.thumbnailUrl && !item.thumbnailUrl.startsWith('http') && item.thumbnailUrl.startsWith('/')) item.thumbnailUrl = imgDomain + item.thumbnailUrl;
                    else if (imgDomain && item && item.thumbnailUrl && !item.thumbnailUrl.startsWith('http')) item.thumbnailUrl = imgDomain + '/' + item.thumbnailUrl;

                    VideoObjectEmbed.push(getVideoObjectSchemaCmsEmbed(seoData, item));
                });
            }
            // if(seoData.videoEmbeds && seoData.videoEmbeds.length > 0) {
            //     seoData.videoEmbeds.forEach((item: any) => {
            //         VideoObject = getVideoObjectSchemaEmbed(seoData, item);
            //     });
            // }
        }
        else if (pageType === PAGE_TYPES.PhotoShow || pageType === PAGE_TYPES.AmpPhotoShow || pageType === PAGE_TYPES.PhotoShowPreview || pageType === PAGE_TYPES.AmpPhotoShowPreview) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.ogUrl) seoData.ogUrl = domain + seoDataMap.ogUrl;
                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                if (seoDataMap.ogType) seoData.ogType = seoDataMap.ogType;
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                if (seoDataMap.twitterUrl) seoData.twitterUrl = domain + seoDataMap.twitterUrl;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;
                if (seoDataMap.newsKeywords) seoData.newsKeywords = seoDataMap.newsKeywords;
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (seoDataMap.canonical) seoData.canonical = seoDataMap.canonical;
                if (seoDataMap.amphtml) seoData.amphtml = domain + seoDataMap.amphtml;
                if (seoDataMap.noindexAndNofollow) seoData.robots = "noindex,nofollow";

                if (seoDataMap.slideShowdata) seoData.slideShowdata = seoDataMap.slideShowdata;
            }

            if( pageType === PAGE_TYPES.PhotoShowPreview || pageType === PAGE_TYPES.AmpPhotoShowPreview ) {
                seoData.robots = "noindex,nofollow";
            }

            if(pageType === PAGE_TYPES.PhotoShow) BreadcrumbList = getBreadcrumbListSchema(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            Article = getArticleSchema(seoData);
            MediaGallery = getMediaGallerySchema(seoData);
        }
        else if (pageType === PAGE_TYPES.ArticleList || pageType === PAGE_TYPES.PhotoList || pageType === PAGE_TYPES.VideoList 
            || pageType === PAGE_TYPES.WebstoryList || pageType === PAGE_TYPES.PodcastList || pageType === PAGE_TYPES.FAQList
            || pageType === PAGE_TYPES.QuizList || pageType === PAGE_TYPES.SurveyList) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.ogUrl && domain) seoData.ogUrl = domain + seoDataMap.ogUrl;
                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                if (seoDataMap.ogType) seoData.ogType = seoDataMap.ogType;
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                if (seoDataMap.twitterUrl && domain) seoData.twitterUrl = domain + seoDataMap.twitterUrl;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;
                if (seoDataMap.newsKeywords) seoData.newsKeywords = seoDataMap.newsKeywords;
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (seoDataMap.canonical) seoData.canonical = seoDataMap.canonical;
            }

            BreadcrumbList = getBreadcrumbListSchema(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
        }
        else if (pageType === PAGE_TYPES.AuthorDetail) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;

                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.newsKeywords) seoData.newsKeywords = seoDataMap.newsKeywords;

                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                seoData.ogType = "person";
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (domain && pathname !== undefined && pathname) seoData.canonical = domain + pathname;
                if (seoData.canonical) seoData.ogUrl = seoData.canonical;
                if (seoData.canonical) seoData.twitterUrl = seoData.canonical;

                if (seoDataMap.fbPages) seoData.fbPages = seoDataMap.fbPages;
                if (seoDataMap.twitterSite) seoData.twitterSite = seoDataMap.twitterSite;
            }

            BreadcrumbList = getBreadcrumbListSchematat(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
        }
        else if (pageType === PAGE_TYPES.Podcast || pageType === PAGE_TYPES.VideoShow || pageType === PAGE_TYPES.AmpVideoShow) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.ogUrl) seoData.ogUrl = domain + seoDataMap.ogUrl;
                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                if (seoDataMap.ogType) seoData.ogType = seoDataMap.ogType;
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                if (seoDataMap.twitterUrl) seoData.twitterUrl = domain + seoDataMap.twitterUrl;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;
                if (seoDataMap.newsKeywords) seoData.newsKeywords = seoDataMap.newsKeywords;
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (seoDataMap.canonical) seoData.canonical = seoDataMap.canonical;
                if (seoDataMap.noindexAndNofollow) seoData.robots = "noindex,nofollow";
            }

            if(pageType === PAGE_TYPES.Podcast || pageType === PAGE_TYPES.VideoShow) BreadcrumbList = getBreadcrumbListSchema(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            // NewsArticle = getNewsArticleSchema(seoData);
            Article = getArticleSchema(seoData);
            VideoObject = getVideoObjectSchema(seoData);
        }
        else if (pageType === PAGE_TYPES.QuizShow || pageType === PAGE_TYPES.SurveyShow) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.ogUrl) seoData.ogUrl = domain + seoDataMap.ogUrl;
                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                if (seoDataMap.ogType) seoData.ogType = seoDataMap.ogType;
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                if (seoDataMap.twitterUrl) seoData.twitterUrl = domain + seoDataMap.twitterUrl;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;
                if (seoDataMap.newsKeywords) seoData.newsKeywords = seoDataMap.newsKeywords;
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (seoDataMap.canonical) seoData.canonical = seoDataMap.canonical;
                if (seoDataMap.noindexAndNofollow) seoData.robots = "noindex,nofollow";
            }

            BreadcrumbList = getBreadcrumbListSchema(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            Article = getArticleSchema(seoData);
        }
        else if (pageType === PAGE_TYPES.TagPage || pageType === PAGE_TYPES.TopicPage) {

            if (data !== undefined && data && data.websiteResouces) {
                if (pathname !== undefined && pathname) seoData.title = decodeURIComponent(decodeURIComponent(getMSID(pathname)));
                if (seoData.title) seoData.ogTitle = seoData.title;
                if (seoData.title) seoData.twitterTitle = seoData.title;

                if (data.websiteResouces.siteDescription) seoData.description = data.websiteResouces.siteDescription;
                if (data.websiteResouces.siteDescription) seoData.ogDescription = data.websiteResouces.siteDescription;
                if (data.websiteResouces.siteDescription) seoData.twitterDescription = data.websiteResouces.siteDescription;
                if (data.websiteResouces.keywords) seoData.keywords = data.websiteResouces.keywords;
                if (seoData.logoUrl) seoData.ogImage = seoData.logoUrl;
                if (seoData.logoUrl) seoData.twitterImage = seoData.logoUrl;
                if (data.websiteResouces.imageWidth) seoData.ogImageWidth = data.websiteResouces.imageWidth;
                if (data.websiteResouces.imageHeight) seoData.ogImageHeight = data.websiteResouces.imageHeight;
                if (data.websiteResouces.siteLanguage) seoData.contentLanguage = data.websiteResouces.siteLanguage;
                seoData.ogType = "website";
                if (domain && pathname !== undefined && pathname) seoData.canonical = domain + decodeURIComponent(decodeURIComponent(pathname));
                if (seoData.canonical) seoData.ogUrl = seoData.canonical;
                if (seoData.canonical) seoData.twitterUrl = seoData.canonical;

                // console.log("data.cookiesTopicRobots : " + data.cookiesTopicRobots);

                // console.log("data host id : " + data.denmarkObject.hostId);
                // console.log("data host pathname : " + data.pathname);
                data.pathname.split("/topics/")[1]

                if(+data.cookiesTopicRobots == 0 )  {
                    console.log("condition is true for cookie");
                    seoData.robots = "noindex,nofollow";
                    console.log("setting no follow no index done...");
                }
            }

            BreadcrumbList = getBreadcrumbListSchematat(seoData);
            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);

        }
        else if (pageType === PAGE_TYPES.StaticPage) {

            if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0) {
                if (seoDataMap.title) seoData.title = seoDataMap.title;
                if (seoDataMap.description) seoData.description = seoDataMap.description;
                if (seoDataMap.keywords) seoData.keywords = seoDataMap.keywords;
                if (seoDataMap.ogTitle) seoData.ogTitle = seoDataMap.ogTitle;
                if (seoDataMap.twitterTitle) seoData.twitterTitle = seoDataMap.twitterTitle;
                if (seoDataMap.ogUrl) seoData.ogUrl = domain + seoDataMap.ogUrl;
                if (seoDataMap.ogImage) seoData.ogImage = seoDataMap.ogImage;
                if (seoDataMap.ogDescription) seoData.ogDescription = seoDataMap.ogDescription;
                seoData.ogType = "website";
                if (seoDataMap.ogImageWidth) seoData.ogImageWidth = seoDataMap.ogImageWidth;
                if (seoDataMap.ogImageHeight) seoData.ogImageHeight = seoDataMap.ogImageHeight;
                if (seoDataMap.twitterUrl) seoData.twitterUrl = domain + seoDataMap.twitterUrl;
                if (seoDataMap.twitterImage) seoData.twitterImage = seoDataMap.twitterImage;
                if (seoDataMap.twitterDescription) seoData.twitterDescription = seoDataMap.twitterDescription;
                if (seoDataMap.contentLanguage) seoData.contentLanguage = seoDataMap.contentLanguage;
                if (seoDataMap.canonical) seoData.canonical = seoDataMap.canonical;
                if (seoDataMap.noindexAndNofollow) seoData.robots = "noindex,nofollow";
            }

            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            WebSite = getWebSiteSchema(seoData);

        }
        else if (pageType === PAGE_TYPES.ErrorPage) {

            if (data !== undefined && data && data.websiteResouces) {
                seoData.title = "Page Not Found";
                seoData.ogTitle = "Page Not Found";
                seoData.twitterTitle = "Page Not Found";
                if (data.websiteResouces.siteDescription) seoData.description = data.websiteResouces.siteDescription;
                if (data.websiteResouces.siteDescription) seoData.ogDescription = data.websiteResouces.siteDescription;
                if (data.websiteResouces.siteDescription) seoData.twitterDescription = data.websiteResouces.siteDescription;
                // if(data.websiteResouces.keywords) seoData.keywords = data.websiteResouces.keywords;
                if (seoData.logoUrl) seoData.ogImage = seoData.logoUrl;
                if (seoData.logoUrl) seoData.twitterImage = seoData.logoUrl;
                if (data.websiteResouces.siteLanguage) seoData.contentLanguage = data.websiteResouces.siteLanguage;
                seoData.ogType = "website";
                seoData.twitterCard = "Page Not Found";
                seoData.robots = "noindex,nofollow";
                // if(data.websiteResouces.imageWidth) seoData.ogImageWidth = data.websiteResouces.imageWidth;
                // if(data.websiteResouces.imageHeight) seoData.ogImageHeight = data.websiteResouces.imageHeight;
            }

            NewsMediaOrganization = getNewsMediaOrganizationSchema(seoData);
            WebPage = getWebPageSchema(seoData);
            // SiteNavigationElement = getSiteNavigationElementSchema(seoData);
            WebSite = getWebSiteSchema(seoData);

        }

        if (seoDataMap !== undefined && seoDataMap && Object.keys(seoDataMap).length > 0 && seoDataMap.googleSchema) {
            // Parse the escaped JSON string to a JSON object
            try {
                let googleSchemas = JSON.parse(seoDataMap.googleSchema);
                if(googleSchemas && Object.keys(googleSchemas).length > 0 && googleSchemas["@type"] && googleSchemas["@type"] === "CmsHowTo") {
                    seoData.googleSchema = transformSchema(googleSchemas, imgDomain, domain, seoData);
                } else seoData.googleSchema = googleSchemas;
            } catch (error) {
                logger.error('Error parsing google site schema JSON string:: ' + error);
                seoData.googleSchema = {};
            }
        }


        // if (data !== undefined && data && data.websiteName !== undefined && data.websiteName !== null && data.websiteName == "Stayyoung") {
        //     seoData.robots = '';
        //     logger.info("data.websiteName == Stayyoung ==>> seoData.robots != noindex,nofollow");
        // }

        // return SEO_META && SEO_STD Data String
        const seoMetaData: any =  getStringSeoMetaData(seoData);

        // console.log("seoMetaData string promise : " , seoMetaData);

        // Structured Data Scripts
        let seoStructuredData: String = `${BreadcrumbList && Object.keys(BreadcrumbList).length > 0 ? `<script type="application/ld+json">${JSON.stringify(BreadcrumbList)}</script>` : ''}` +
            `${NewsMediaOrganization && Object.keys(NewsMediaOrganization).length > 0 ? `<script type="application/ld+json">${JSON.stringify(NewsMediaOrganization)}</script>` : ''}` +
            `${WebPage && Object.keys(WebPage).length > 0 ? `<script type="application/ld+json">${JSON.stringify(WebPage)}</script>` : ''}` +
            // `${SiteNavigationElement && Object.keys(SiteNavigationElement).length > 0 ? `<script type="application/ld+json">${JSON.stringify(SiteNavigationElement)}</script>` : ''}` +
            `${NewsArticle && Object.keys(NewsArticle).length > 0 ? `<script type="application/ld+json">${JSON.stringify(NewsArticle)}</script>` : ''}` +
            `${Article && Object.keys(Article).length > 0 ? `<script type="application/ld+json">${JSON.stringify(Article)}</script>` : ''}` +
            `${VideoObject && Object.keys(VideoObject).length > 0 ? `<script type="application/ld+json">${JSON.stringify(VideoObject)}</script>` : ''}` +
            `${MediaGallery && Object.keys(MediaGallery).length > 0 ? `<script type="application/ld+json">${JSON.stringify(MediaGallery)}</script>` : ''}` +
            `${WebSite && Object.keys(WebSite).length > 0 ? `<script type="application/ld+json">${JSON.stringify(WebSite)}</script>` : ''}` +
            `${seoData.googleSchema && Object.keys(seoData.googleSchema).length > 0 ? `<script type="application/ld+json">${JSON.stringify(seoData.googleSchema)}</script>` : ''}`;

        if(VideoObjectEmbed && VideoObjectEmbed.length > 0) {
            VideoObjectEmbed.forEach((item: any) => {
                seoStructuredData = seoStructuredData + `${item && Object.keys(item).length > 0 ? `<script type="application/ld+json">${JSON.stringify(item)}</script>` : ''}`;
            });
        }

        // console.log("\n\n\nseoMetaData : "+ seoMetaData);
        // console.log("\n\n\nseoStructuredData : " + seoStructuredData);
        
        return `${seoMetaData && seoMetaData}${seoStructuredData && seoStructuredData}`;

    } catch (error) {
        logger.info(error, "error :- ");
    }
};

const getBreadcrumbListSchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": seoData.breadcrumbs
            ? [...seoData.breadcrumbs.map((item: any, index: number) => ({
                    "@type": "ListItem",
                    "position": index + 1,
                    "item": {
                        "@type": "Thing",
                        "@id": seoData.websiteUrl && (item.url || !index) ? seoData.websiteUrl + item.url : undefined,
                        "name": item.name || undefined,
                    },
                })),
                {
                    "@type": "ListItem",
                    "position": seoData.breadcrumbs.length + 1,
                    "item": {
                        "@type": "Thing",
                        "@id": seoData.ogUrl || undefined,
                        "name": seoData.ogTitle || undefined,
                    },
                }
            ]
            : [],
    };
    return structuredData;
};

const getBreadcrumbListSchematat = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@type": "Thing",
                        "@id": seoData.websiteUrl || undefined,
                        "name": seoData.websiteName || undefined,
                    },
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@type": "Thing",
                        "@id": seoData.ogUrl || undefined,
                        "name": seoData.ogTitle || undefined,
                    },
                }
            ],
    };
    return structuredData;
};

const getNewsMediaOrganizationSchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "NewsMediaOrganization",
        "name": seoData.websiteName || undefined,
        "url": seoData.websiteUrl || undefined,
        "logo": seoData.logoUrl
            ? {
                "@type": "ImageObject",
                "url": seoData.logoUrl,
                "height": seoData.logoHeight || undefined,
                "width": seoData.logoWidth || undefined,
            }
            : undefined,
    };
    return structuredData;
};

const getWebPageSchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": seoData.title || undefined, // "home" in case of home page from 1.0
        "description": seoData.description || undefined, // "home" in case of home page from 1.0
        "keywords": seoData.keywords || undefined,
        "url": seoData.ogUrl || undefined,
    };
    return structuredData;
};

const getWebSiteSchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": seoData.websiteUrl || undefined,
        "name": seoData.websiteName || undefined,
        "potentialAction": {
                "@type": "SearchAction",
                "target": {
                    "@type": "EntryPoint",
                    "urlTemplate": seoData.websiteUrl ? seoData.websiteUrl + "/topics/{search_term_string}" : undefined,
                },
                "query-input": "required name=search_term_string",
            },
    };
    return structuredData;
};

// const getSiteNavigationElementSchema = (seoData) => {

//     // Merge arrays using the spread operator
//     const navigationItems = [...seoData.headerNavigationItems, ...seoData.footerNavigationItems];

//     const structuredData = {
//         "@context": "https://schema.org",
//         "@type": "ItemList",
//         "itemListElement": navigationItems
//             ? navigationItems.map((item) => ({
//                 "@type": "SiteNavigationElement",
//                 "name": item.name || undefined,
//                 "url": seoData.websiteUrl && item.url ? seoData.websiteUrl + item.url : undefined,
//             }))
//             : [],
//     };
//     return structuredData;
// };

const getArticleSchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": seoData.ogUrl || undefined,
            "description": seoData.description || undefined,
            "thumbnailUrl": seoData.thumbnailUrl || undefined,
        },
        "headline": seoData.title || undefined,
        "inLanguage": seoData.contentLanguage || undefined,
        "articleBody": seoData.articleBody || undefined,
        "articleSection": seoData.sectionName && Object.keys(seoData.sectionName).length > 0 ? seoData.sectionName[-1] : undefined,
        "image": seoData.thumbnailUrl
            ? {
                "@type": "ImageObject",
                "url": seoData.thumbnailUrl || undefined,
                "height": '720',
                "width": '1280',
            }
            : undefined,
        "datePublished": seoData.publishedDate || undefined,
        "dateModified": seoData.modifiedDate || undefined,
        "author": seoData.author && Object.keys(seoData.author).length > 0
            ? seoData.author.map((_author: any) => {
                return {"@type": "Person",
                "name": _author && _author.name ? _author.name : undefined,
                "url": _author && _author.name && _author.id && seoData.websiteUrl 
                ? seoData.websiteUrl + '/' + _author.name + '/author/' + _author.id + '.html' : undefined,
                }
            })
            : undefined,
        "publisher": seoData.websiteName
            ? {
                "@type": "NewsMediaOrganization",
                "name": seoData.websiteName,
                "logo": seoData.logoUrl
                    ? {
                        "@type": "ImageObject",
                        "url": seoData.logoUrl,
                        "height": seoData.logoHeight || undefined,
                        "width": seoData.logoWidth || undefined,
                    }
                    : undefined,
            }
            : undefined,
        "description": seoData.description || undefined,
        "keywords": seoData.keywords || undefined,
    };
    return structuredData;
};


const getVideoObjectSchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "mainEntityOfPage": seoData.ogUrl || undefined,
        "name": seoData.title || undefined,
        "duration": seoData.duration || undefined,
        "contentUrl": seoData.ogUrl || undefined,
        // "embedUrl": seoData.embedUrl || undefined,
        "description": seoData.description || undefined,
        "datePublished": seoData.publishedDate || undefined,
        "dateModified": seoData.modifiedDate || undefined,
        "uploadDate": seoData.uploadDate || undefined,
        "thumbnailUrl": seoData.thumbnailUrl || undefined,
        "headline": seoData.title || undefined,
        "keywords": seoData.keywords || undefined,
        "image": seoData.thumbnailUrl
            ? {
                "@type": "ImageObject",
                "url": seoData.thumbnailUrl,
                "height": '720',
                "width": '1280',
            }
            : undefined,
        "author": seoData.author && Object.keys(seoData.author).length > 0
            ? seoData.author.map((_author: any) => {
                return {"@type": "Person",
                "name": _author && _author.name ? _author.name : undefined,
                "url": _author && _author.name && _author.id && seoData.websiteUrl 
                ? seoData.websiteUrl + '/' + _author.name + '/author/' + _author.id + '.html' : undefined,
                }
            })
            : undefined,
        "publisher": seoData.websiteName
            ? {
                "@type": "Organization",
                "name": seoData.websiteName || undefined,
                "url": seoData.websiteUrl || undefined,
                "logo": seoData.logoUrl
                    ? {
                        "@type": "ImageObject",
                        "url": seoData.logoUrl || undefined,
                        "height": seoData.logoHeight || undefined,
                        "width": seoData.logoWidth || undefined,
                    }
                    : undefined,
            }
            : undefined,
    };
    return structuredData;
};

const getVideoObjectSchemaCmsEmbed = (seoData, embeditem) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "mainEntityOfPage": seoData.websiteUrl && embeditem.seopath &&  embeditem.msid ? seoData.websiteUrl + '/' + embeditem.seopath + "/videoshow/" + embeditem.msid + ".html" : undefined,
        "name": embeditem.title || undefined,
        "duration": msToISO8601(embeditem.media.durationms) || undefined,
        "contentUrl": seoData.websiteUrl && embeditem.seopath &&  embeditem.msid ? seoData.websiteUrl + '/' + embeditem.seopath + "/videoshow/" + embeditem.msid + ".html" : undefined,
        // "embedUrl": seoData.embedUrl || undefined, 
        "description": embeditem.media.description || undefined,
        "datePublished": convertTimestampToISO(embeditem.insertdate) || undefined,
        "dateModified": convertTimestampToISO(embeditem.updatedate) || undefined,
        "uploadDate": convertTimestampToISO(embeditem.insertdate) || undefined,
        "thumbnailUrl": embeditem.thumbnailUrl || undefined,
        "headline": embeditem.title || undefined,
        "keywords": getCommaSeparatedNames(embeditem.keywords) || undefined,
        "image": embeditem.thumbnailUrl
            ? {
                "@type": "ImageObject",
                "url": embeditem.thumbnailUrl,
                "height": '720',
                "width": '1280',
                // "height": embeditem.metainfo && embeditem.metainfo.OriginalImageHeightPx && embeditem.metainfo.OriginalImageHeightPx.value ? embeditem.metainfo.OriginalImageHeightPx.value : undefined,
                // "width": embeditem.metainfo && embeditem.metainfo.OriginalImageWidthPx && embeditem.metainfo.OriginalImageWidthPx.value ? embeditem.metainfo.OriginalImageWidthPx.value : undefined,
            }
            : undefined,
        "author": embeditem.authors && Object.keys(embeditem.authors).length > 0
            ? embeditem.authors.map((_author: any) => {
                return {"@type": "Person",
                "name": _author && _author.name ? _author.name : undefined,
                "url": _author && _author.name && _author.id && seoData.websiteUrl 
                ? seoData.websiteUrl + '/' + _author.name + '/author/' + _author.id + '.html' : undefined,
                }
            })
            : undefined,
        "publisher": seoData.websiteName
            ? {
                "@type": "Organization",
                "name": seoData.websiteName || undefined,
                "url": seoData.websiteUrl || undefined,
                "logo": seoData.logoUrl
                    ? {
                        "@type": "ImageObject",
                        "url": seoData.logoUrl || undefined,
                        "height": seoData.logoHeight || undefined,
                        "width": seoData.logoWidth || undefined,
                    }
                    : undefined,
            }
            : undefined,
    };
    return structuredData;
};

// const getVideoObjectSchemaEmbed = (seoData, embeditem) => {
//     const structuredData = {
//         "@context": "https://schema.org",
//         "@type": "VideoObject",
//         "mainEntityOfPage": embeditem.id ?  "https://t.sli.ke/v." + embeditem.id : undefined,
//         // "name": seoData.title || undefined,
//         "duration": seoData.duration || undefined,
//         "contentUrl": embeditem.id ?  "https://t.sli.ke/v." + embeditem.id : undefined,
//         // "embedUrl": seoData.embedUrl || undefined, 
//         // "description": seoData.description || undefined,
//         // "datePublished": seoData.publishedDate || undefined,
//         // "dateModified": seoData.modifiedDate || undefined,
//         // "uploadDate": seoData.uploadDate || undefined,
//         "thumbnailUrl": embeditem.thumbUrl || undefined,
//         // "headline": seoData.title || undefined,
//         // "keywords": seoData.keywords || undefined,
//         "image": seoData.thumbUrl
//             ? {
//                 "@type": "ImageObject",
//                 "url": seoData.thumbnailUrl,
//                 // "height": seoData.imageHeight || undefined,
//                 // "width": seoData.imageWidth || undefined,
//             }
//             : undefined,
//         "publisher": seoData.websiteName
//             ? {
//                 "@type": "Organization",
//                 "name": seoData.websiteName || undefined,
//                 "url": seoData.websiteUrl || undefined,
//                 "logo": seoData.logoUrl
//                     ? {
//                         "@type": "ImageObject",
//                         "url": seoData.logoUrl || undefined,
//                         "height": seoData.logoHeight || undefined,
//                         "width": seoData.logoWidth || undefined,
//                     }
//                     : undefined,
//             }
//             : undefined,
//     };
//     return structuredData;
// };

const getMediaGallerySchema = (seoData) => {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "MediaGallery",
        "name": seoData.title || undefined,
        "description": seoData.description || undefined,
        "mainEntityOfPage": seoData.slideShowdata
            ? {
                "@type": "ImageGallery",
                "associatedMedia": seoData.slideShowdata.map((mediaObject) => ({
                    "@type": mediaObject.type && mediaObject.type === "IMAGES" ? "ImageObject" : undefined,
                    "name": mediaObject.name || undefined,
                    "description": mediaObject.description || undefined,
                    "caption": mediaObject.caption || undefined,
                    "thumbnailUrl": seoData.imgDomain && mediaObject.thumbnailUrl ? seoData.imgDomain + mediaObject.thumbnailUrl : undefined,
                    "contentUrl": seoData.websiteUrl && mediaObject.contentUrl ? seoData.websiteUrl + mediaObject.contentUrl : undefined,
                    "duration": msToISO8601(mediaObject.duration) || undefined,
                })),
            }
            : undefined,
    };
    return structuredData;
};

function resizeHeight(heightpxl: string, widthpxl: string, resizedWidth: number): string {
    try {
        // Parse the input strings to numbers
        const height = parseFloat(heightpxl);
        const width = parseFloat(widthpxl);

        // Check if parsed values are valid numbers
        if (isNaN(height) || isNaN(width) || width === 0) {
            console.error("Invalid input dimensions.");
            return '';
        }

        // Calculate the aspect ratio and resized height
        const resizedHeight = (height * resizedWidth) / width;

        // Round off the resized height to the nearest integer
        const roundedHeight = Math.round(resizedHeight);

        // Convert the resized height back to a string and return
        return roundedHeight.toString();
    } catch (error) {
        console.error("Error resizing height:", error);
        return '';
    }
}

// convert Timestamp To ISO
// const convertTimestampToISO = (timestamp) => {
//     const date = new Date(parseInt(timestamp, 10));
//     const timezoneOffset = -date.getTimezoneOffset();
//     const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
//     const offsetMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
//     const offsetSign = timezoneOffset >= 0 ? '+' : '-';
//     const isoString = date.toISOString();
//     const localISOTime = `${isoString.substring(0, isoString.length - 1)}${offsetSign}${offsetHours}:${offsetMinutes}`;
//     return localISOTime;
// };

const convertTimestampToISO = (timestamp) => {
    try {
        // Check if the timestamp is provided and is a valid number
        if (timestamp === undefined || timestamp === null) {
            throw new Error("Timestamp is required and cannot be null or undefined.");
        }

        // Ensure the timestamp is a string or a number
        if (typeof timestamp !== 'string' && typeof timestamp !== 'number') {
            throw new Error("Timestamp must be a string or a number.");
        }

        // Convert the timestamp to a number if it's a string
        const parsedTimestamp = Number(timestamp);

        // Check if the parsed timestamp is a valid number
        if (isNaN(parsedTimestamp)) {
            throw new Error("Invalid timestamp: Unable to parse into a number.");
        }

        // Create a Date object using the parsed timestamp
        const date = new Date(parsedTimestamp);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid timestamp: Unable to convert to a valid date.");
        }

        // Get the timezone offset in minutes
        const timezoneOffset = -date.getTimezoneOffset();

        // Calculate the hours and minutes of the offset
        const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
        const offsetMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
        
        // Determine the sign of the offset
        const offsetSign = timezoneOffset >= 0 ? '+' : '-';

        // Get the ISO string without the 'Z' at the end
        const isoString = date.toISOString();

        // Construct the local ISO time string
        const localISOTime = `${isoString.substring(0, isoString.length - 1)}${offsetSign}${offsetHours}:${offsetMinutes}`;
        
        return localISOTime;

    } catch (error) {
        // Handle the error by returning a message or taking other actions as needed
        logger.error("Error converting timestamp to ISO format:", error);
        return '';  // or return a fallback value if needed
    }
};


function msToISO8601(duration) {
    // If duration is a string, check if it contains only digits
    if (typeof duration === "string") {
      if (!/^\d+$/.test(duration)) {
        logger.error("Invalid input: The provided string is not a numeric duration.");
        return duration; // Return the original argument
      }
      duration = parseInt(duration, 10); // Convert string to a number
    }
  
    // Validate if the input is a valid positive number
    if (isNaN(duration) || duration < 0) {
      logger.error("Invalid input: Please provide a positive numeric duration.");
      return duration; // Return the original argument
    }
  
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(duration / 3600000);
    const minutes = Math.floor((duration % 3600000) / 60000);
    const seconds = Math.floor(((duration % 3600000) % 60000) / 1000);
  
    // Build the ISO 8601 duration string
    let isoDuration = "PT";
    if (hours > 0) isoDuration += `${hours}H`;
    if (minutes > 0) isoDuration += `${minutes}M`;
    if (seconds > 0 || (!hours && !minutes)) isoDuration += `${seconds}S`;
  
    return isoDuration;
  }

// Function to convert keywords list to comma-separated string
const getCommaSeparatedNames = (keywords) => {
    if (!Array.isArray(keywords) || keywords.length === 0) {
        return '';
    }

    // Filter the array to include only valid objects with a 'name' property
    const names = keywords
        .filter(keyword => keyword && typeof keyword.name === 'string')
        .map(keyword => keyword.name.trim());

    // Join the names with a comma and return
    return names.join(', ');
};

// Define TypeScript types for the schema 
  type CmsHowTo = {
    "@context": string[],
    "@type": string,
    "description": string,
    "name": string,
    "supply": string,
    "step": HowToStep[],
    "totalTime": string,
  };
  
  type HowToStep = {
    "@type": string,
    "name": string,
    "text": string,
    "url": string,
    "image"?: {
      "@type": string,
      "url": string,
      "name": string,
    },
    "video"?: {
      "@type": string,
      "url": string,
      "name": string,
      "contentUrl": string,
//    duration: string;
//    description: string;
//    uploadDate: string;
//    thumbnailUrl: string;
    },
  };
  
  const transformSchema = (data: any, imgDomain: string, domain: string, seoData: any): CmsHowTo => {
    // Ensure data.step__cms is an array
    const steps = Array.isArray(data.step__cms) ? data.step__cms : [data.step__cms];
  
    // Transform the schema according to Google site requirements
    const transformedData: CmsHowTo = {
      "@context": ["http://schema.org"],
      "@type": "HowTo",
      "description": data.description,
      "name": data.name,
      "supply": data.supply,
      "step": steps.map((step: any) => {
        const result: HowToStep = {
          "@type": "HowToStep",
          "name": step.name.replace(/<br\\>/g, '<br>'),  // Convert escaped <br\> to <br>
          "text": step.text,
          "url": seoData && seoData.ogUrl,
        };
  
        // Conditionally add `image` or `video` based on the content of step
        if (step.image__cmsEmbed) {
          result.image = {
            "@type": "ImageObject",
            "url": `${imgDomain}/thumb/${step.image__cmsEmbed.msid}.cms?resizemode=4`,
            "name": step.image__cmsEmbed.name,
          };
        }
  
        if (step.video__cmsEmbed) {
          result.video = {
            "@type": "VideoObject",
            "url": `${domain}/storyurl/videoshow/${step.video__cmsEmbed.url}.html`,
            "name": step.video__cmsEmbed.name,
            "contentUrl": `${domain}/storyurl/videoshow/${step.video__cmsEmbed.url}.html`,
          };
        }
  
        return result;
      }),
      "totalTime": data.totalTime,
    };
  
    return transformedData;
  };
  // Utility function to encode HTML entities for safer usage in meta tags
const encodeHTML = (str) => {
    if (typeof str !== "string") return str;
    return str
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  };


const getStringSeoMetaData =  (seoData) => {

    // await fetchMedleyData(387, data.pathname.split("/topics/")[1])
    // .then(response => {
    //     console.log("Tag page response from medlay seometadata: ", response);

    //     if (response.data[0].topics && response.data[0].topics.length == 0) {

    //         console.log("Tag length  == 0");
    //         seoData.robots = "noindex,nofollow";
    //     }
    // })

    // console.log("seoData.robots final : " + seoData.robots);
    // Meta Tags
    let seoMetaData = `${seoData.title ? `<title>${encodeHTML(seoData.title)}</title>` : ''}` +
        `${seoData.description ? `<meta name="description" content="${encodeHTML(seoData.description)}" />` : ''}` +
        `${seoData.keywords ? `<meta name="keywords" content="${encodeHTML(seoData.keywords)}" />` : ''}` +
        `${seoData.robots ? '' : `<meta name="robots" content="NOODP" />`}` +
        `${seoData.robots ? `<meta name="robots" content="${seoData.robots}" />` : `<meta name="robots" content="max-image-preview:large" />`}` +
        `${seoData.ogTitle ? `<meta property="og:title" content="${encodeHTML(seoData.ogTitle)}" />` : ''}` +
        `${seoData.ogUrl ? `<meta property="og:url" content="${seoData.ogUrl}" />` : ''}` +
        `${seoData.ogImage ? `<meta property="og:image" content="${seoData.ogImage}" />` : ''}` +
        `${seoData.ogDescription ? `<meta property="og:description" content="${encodeHTML(seoData.ogDescription)}" />` : ''}` +
        `${seoData.ogType ? `<meta property="og:type" content="${seoData.ogType}" />` : ''}` +
        `${seoData.websiteName ? `<meta property="og:site_name" content="${seoData.websiteName}" />` : ''}` +
        // `${seoData.ogImageWidth ? `<meta property="og:image:width" content="${seoData.ogImageWidth}" />` : ''}` +
        // `${seoData.ogImageHeight ? `<meta property="og:image:height" content="${seoData.ogImageHeight}" />` : ''}` +
        `<meta property="og:image:width" content="1280" />` +
        `<meta property="og:image:height" content="720" />` +
        `${seoData.twitterUrl ? `<meta property="twitter:url" content="${seoData.twitterUrl}" />` : ''}` +
        `${seoData.twitterImage ? `<meta property="twitter:image" content="${seoData.twitterImage}" />` : ''}` +
        `${seoData.twitterTitle ? `<meta property="twitter:title" content="${encodeHTML(seoData.twitterTitle)}" />` : ''}` +
        `${seoData.twitterDescription ? `<meta property="twitter:description" content="${encodeHTML(seoData.twitterDescription)}" />` : ''}` +
        `${seoData.twitterCard ? '' : `<meta name="twitter:card" content="summary_large_image" />`}` +
        `${seoData.twitterSite ? `<meta name="twitter:site" content="${seoData.twitterSite}" />` : ''}` +
        `${seoData.fbAdmins ? `<meta property="fb:admins" content="${seoData.fbAdmins}" />` : ''}` +
        `${seoData.fbPages ? `<meta property="fb:pages" content="${seoData.fbPages}" />` : ''}` +
        `${seoData.fbAppId ? `<meta property="fb:app_id" content="${seoData.fbAppId}" />` : ''}` +
        `${seoData.googleSiteVerification ? `<meta name="google-site-verification" content="${seoData.googleSiteVerification}" />` : ''}` +
        `${seoData.newsKeywords ? `<meta name="news_keywords" content="${encodeHTML(seoData.newsKeywords)}" />` : ''}` +
        `${seoData.mobileWebAppCapable ? `<meta name="mobile-web-app-capable" content="${seoData.mobileWebAppCapable}" />` : ''}` +
        `${seoData.themeColor ? `<meta name="theme-color" content="${seoData.themeColor}" />` : ''}` +
        `${seoData.appleMobileWebAppCapable ? `<meta name="apple-mobile-web-app-capable" content="${seoData.appleMobileWebAppCapable}" />` : ''}` +
        `<meta name="HandheldFriendly" content='true' />` +
        `<meta name="MobileOptimized" content='width' />` +
        `<meta http-equiv="X-UA-Compatible" content="ie=edge" />` +
        `<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />` +
        `${seoData.contentLanguage ? `<meta http-equiv="content-language" content="${seoData.contentLanguage}" />` : ''}` +
        `${seoData.yKey ? `<meta name="y_key" content="${seoData.yKey}" />` : ''}` +
        `${seoData.canonical ? `<link rel="canonical" href="${seoData.canonical}" />` : ''}` +
        `${seoData.thumbnailUrl ? `<link rel="preload" as="image" href="${seoData.thumbnailUrl}" />` : ''}` +
        `${seoData.amphtml ? `<link rel="amphtml" href="${seoData.amphtml}" />` : ''}`;
    // ${seoData.ogUrl ? `<link rel="alternate" href="${seoData.ogUrl}" hrefLang="en-IN" />` : ''}

    return seoMetaData;
}

