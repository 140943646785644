export let deFaultValues = {
	ENV: 'production',// 'development',// 'production', // 'development1'
	PORT: 8000,
	PUBLIC_URL: 'auto',
	PREFIX_URL: '/',
	ONLY_EXACT_PATH: false,
};

/* if( !(window.location.href.includes('getm360.com') || window.location.href.includes('localhost')) ) {
	deFaultValues.ENV = 'production';
} */


export const config = {
	ENV: process.env.NODE_ENV ? process.env.NODE_ENV : deFaultValues.ENV,
	PORT: process.env.PORT ? process.env.PORT : deFaultValues.PORT,
	PUBLIC_URL: process.env.PUBLIC_URL ? process.env.PUBLIC_URL : deFaultValues.PUBLIC_URL,
	PREFIX_URL: process.env.PREFIX_URL ? process.env.PREFIX_URL : deFaultValues.PREFIX_URL,
	ONLY_EXACT_PATH: process.env.ONLY_EXACT_PATH ? process.env.ONLY_EXACT_PATH === 'true' : deFaultValues.ONLY_EXACT_PATH,
};

export default config;
