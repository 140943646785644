// TopicPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PAGE_TYPES } from '@fronend/common/constants';
import { getHostName, getPageData, getRenderOut } from '@fronend/common/utils';
import { fetchHomeDataIfNeeded } from '@components/Home/HomeActions';
import { connect } from 'react-redux';
import '../../../reducers/topicpage/topicpage';


TopicPage.events = {
	SUCCESS: 'SEARCH_PAGE_SUCCESS',
	FAILURE: 'SEARCH_PAGE_FAILURE'
};

TopicPage.fetchData = async function({ dispatch, query, params, cookies, headers, hostname, req, res, loading }) {
	return dispatch(fetchHomeDataIfNeeded(params, query, cookies, headers, hostname, req, PAGE_TYPES.TopicPage, TopicPage.events, res, loading));
};

function TopicPage(props: { data: any, iscsr: any; }) {

	const [pageJson, setpageJson] = useState<any>(null);
	useEffect( () => {
		if( !props.data && !pageJson ) {
			const hostname = getHostName();
			const Promise1 = getPageData(hostname, PAGE_TYPES.TopicPage);
			Promise1.then( res => {
				setpageJson(res);
			} );
		}
	}, [pageJson] );
	
	return (
		<div>
			{getRenderOut(props, pageJson, null)}
		</div>	
	);

}


const mapStateToProps = (state) => ({
	data: state.topicpage.component_data,
	iscsr: state.iscsr,
});

export default connect(mapStateToProps)(TopicPage);
