// ArticleShow.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PAGE_TYPES } from '@fronend/common/constants';
import { getHostName, getPageData, getRenderOut } from '@fronend/common/utils';
import { fetchHomeDataIfNeeded } from '@components/Home/HomeActions';
import { connect } from 'react-redux';
import './../../../reducers/articleshow/articleshow';

ArticleShow.events = {
	SUCCESS: 'ARTICLE_SHOW_SUCCESS',
	FAILURE: 'ARTICLE_SHOW_FAILURE'
};

ArticleShow.fetchData = async function({ dispatch, query, params, cookies, headers, hostname, req, res, loading }) {
	return dispatch(fetchHomeDataIfNeeded(params, query, cookies, headers, hostname, req, PAGE_TYPES.ArticleShow, ArticleShow.events, res, loading));
};

function ArticleShow(props: { data: any, iscsr: any; }) {

	const [pageJson, setpageJson] = useState<any>(null);
	useEffect( () => {
		if( !props.data && !pageJson ) {
			const hostname = getHostName();
			const Promise1 = getPageData(hostname, PAGE_TYPES.ArticleShow);
			Promise1.then( res => {
				setpageJson(res);
			} );
		}
	}, [pageJson] );
	
	return (
		<div>
			{getRenderOut(props, pageJson, null)}
		</div>	
	);

}


const mapStateToProps = (state) => ({
	data: state.articleshow.component_data, 
	iscsr: state.iscsr,
});

export default connect(mapStateToProps)(ArticleShow);
