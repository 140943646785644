// Home.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PAGE_TYPES } from '@fronend/common/constants';
import { getHostName, getPageData, getRenderOut } from '@fronend/common/utils';
import { connect } from 'react-redux';
import { fetchHomeDataIfNeeded } from './HomeActions';
import './../../../reducers/home/home';
import './../../../../src/index.css';
import logger from '../../../logging/log';

Home.events = {
	SUCCESS: 'HOME_SUCCESS',
	FAILURE: 'HOME_FAILURE',
};

Home.fetchData = function ({ dispatch, query, params, cookies, headers, hostname, req, res, loading }) {
	return dispatch(fetchHomeDataIfNeeded(params, query, cookies, headers, hostname, req, PAGE_TYPES.HomePage, Home.events, res, loading));
};

function Home(props: { data: any, iscsr: any; }) {
	const [pageJson, setpageJson] = useState<any>(null);
	useEffect(() => {
		if (!props.data && !pageJson) {
			const hostname = getHostName();
			const Promise1 = getPageData(hostname, PAGE_TYPES.HomePage);
			Promise1.then((res) => {
				setpageJson(res);
			});
		}
	}, [pageJson]);

	return (
		<div>
			{getRenderOut(props, pageJson, null)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	data: state.home.component_data,
	iscsr: state.iscsr,
});

export default connect(mapStateToProps)(Home);
